import type { RouteLocationNormalized } from '#vue-router'

export default defineNuxtPlugin(() => {
  if (process.env.VITEST) {
    return
  }

  const router = useRouter()
  const nuxtApp = useNuxtApp()
  const { pageState } = usePageState()

  const trackingPromise = useTrackingEvents()

  nuxtApp.runWithContext(() => {
    console.log('nuxtApp shop', nuxtApp.$currentShop)
    const track = (
      to?: RouteLocationNormalized,
      from?: RouteLocationNormalized,
    ) => {
      const current = to ?? toValue(router.currentRoute)
      const pageTypeId = current?.params?.slug?.toString() || ''
      const contentName = current?.path || ''
      const doc = typeof document === 'undefined' ? null : document

      setTimeout(() => {
        trackingPromise.then(({ trackPageView }) =>
          trackPageView({
            eventName: 'FielmannBasic_VirtualPageview',
            contentName,
            title: doc?.title || '',
            pageType: pageState.value.pageType as PageType,
            pageTypeId,
            clickOrigin: from?.fullPath || '',
          }),
        )
      }, 500) // give time to resolve stuff from api before tracking to have complete payload
    }

    router?.afterEach((to, from) => {
      if (from.path === to.path && from.name) {
        // skip tracking when to and from paths are the same.
        // this can happen when:
        // - entering the shop through a search engine, since the canonical link does not include any params
        // - when using our own search, since we can't load variants for search results,
        //   because the result could be contact lenses with thousands of variants
        // - "from.name" is used to track initial content view event when coming from external source
        // on the landing page since in that case from.path and to.path are the same and it skips to
        // track the initial view of the landing page
        return
      }
      track(to, from)
    })
  })
})
