import {
  type BaskteItemDisplayDataItem,
  getAttributeValue,
  getFirstAttributeValue,
  type Product,
  type RpcContext,
  type Variant,
} from '@scayle/storefront-nuxt'
import type { NuxtApp } from 'nuxt/app'
import {
  defaultLensQualityRefs,
  entryGlassIncompatibleRimtypes,
  FACTOR_ENTRY,
  FACTOR_STANDARD,
  FACTOR_STANDARD_PLUS,
  prescriptionFormatter,
  prescriptionPropUnits,
  rxLensQualityRefKeys,
  rxRecommendationMapping,
} from '../constants/rx'
import { RxConfMasterKeys } from '../rpcMethods/rxTypes'
import {
  type IPrescriptionValue,
  type IPrescriptionValues,
  isPrescriptionProp,
  type RxPrescriptionValues,
  type RxPrescriptionValue,
} from './rxTypes'
import { getFrameSizesForVariant } from './glasses'
import type { FimOrderItem } from './order'
import { getIsoCountry, toLocaleString } from './locale'
import { getAdvancedAttribute } from './attribute'
import type { FimBasketItem, ItemGroup } from '~/composables/useFimBasket'
import type {
  ConfiguratorSerializedAddon,
  ConfiguratorSerializedMain,
  ConfiguratorStateValue,
  RxSubItemInfo,
} from '~/composables/useRxConfiguratorState'
import type { CustomerOrderResponse, IFittingHeightMapped } from '~/rpcMethods'
import type { ContactLensValue } from '~/utils/contactLenses'

export enum RxDisplayDataAttributes {
  colorAttribute = 'attribute-1',
  sizeAttribute = 'attribute-2',
  prescriptionValuesRightAttribute = 'attribute-3',
  prescriptionValuesLeftAttribute = 'attribute-4',
}

export type RxBasketItemDisplayDataKey =
  | RxDisplayDataAttributes.colorAttribute
  | RxDisplayDataAttributes.sizeAttribute
  | RxDisplayDataAttributes.prescriptionValuesLeftAttribute
  | RxDisplayDataAttributes.prescriptionValuesRightAttribute

export type RxBasketItemDisplayData = Partial<
  Record<RxBasketItemDisplayDataKey, BaskteItemDisplayDataItem>
>

export type FormattableLensesPrescriptionProp = keyof Omit<
  ContactLensValue & RxPrescriptionValue,
  'contactLensColor' | 'hpd'
>

export const getLensName = (product: Product | undefined | null): string =>
  getFirstAttributeValue(product?.attributes, 'rxProductNameLong')?.label ??
  getFirstAttributeValue(product?.attributes, 'name')?.label ??
  ''

export const getFrameColorAndSize = (product: Product, variant: Variant) => {
  const frameColor = getFirstAttributeValue(
    product.attributes,
    'frameColor',
  )?.label

  const sizes = getFrameSizesForVariant(variant)

  return [frameColor, sizes].filter(Boolean).join(' · ')
}

export type RXItem =
  | ConfiguratorSerializedMain
  | ConfiguratorSerializedAddon
  | FimBasketItem
  | FimOrderItem
  | OrderItem

type ItemWithItemGroup = ObjectWith<{
  itemGroup?: ItemGroup | null
  customData?: ObjectWith<{ itemGroup?: ItemGroup | null }>
}>

export const getItemGroup = (item: ItemWithItemGroup): ItemGroup | undefined =>
  (item?.itemGroup as ItemGroup) ?? item?.customData?.itemGroup

export const hasItemGroup = (item: ItemWithItemGroup) =>
  Boolean(item?.itemGroup || item?.customData?.itemGroup)

export const isRxMainItem = (item: ItemWithItemGroup) =>
  getItemGroup(item)?.isMainItem ?? false

export const isRxAddonItem = (item: ItemWithItemGroup) =>
  hasItemGroup(item) && !isRxMainItem(item)

export const getItemGroupId = (item: ItemWithItemGroup) => getItemGroup(item)?.id ?? ''

export const getItemGroupMainItem = (
  items: ItemWithItemGroup[],
  id: string,
): ItemWithItemGroup | undefined =>
  items.find((item) => isRxMainItem(item) && getItemGroupId(item) === id)

export const getItemGroupAddonItems = (items: ItemWithItemGroup[], id: string): ItemWithItemGroup[] =>
  items.filter((item) => isRxAddonItem(item) && getItemGroupId(item) === id)

const getRxAttributes = (
  item: ConfiguratorStateValue,
  includeOnly: string[] = [],
) => {
  return [
    ...Object.values(item.product.attributes ?? {}),
    ...Object.values(item.variant.attributes ?? {}),
  ].reduce(
    (prevVal, attribute) => {
      if (
        attribute &&
        (!includeOnly.length || includeOnly.includes(attribute.key)) &&
        attribute.key.startsWith('rx')
      ) {
        return {
          ...prevVal,
          [attribute.key]: Array.isArray(attribute.values)
            ? attribute.values[0].label
            : attribute.values.label,
        }
      }
      return prevVal
    },
    {} as { [key: string]: string },
  )
}

const getRxSubItemName = (item: ConfiguratorStateValue) =>
  getFirstAttributeValue(item.product?.attributes, 'name')?.label ??
  getAdvancedAttribute({ product: item.product, property: 'productName' }) ??
  ''

const generateRxSubItemInfo = (
  addonItems: ConfiguratorStateValue[],
): RxSubItemInfo[] =>
  addonItems.map((addonItem) => {
    const tintingColorDescription = Object.values(
      getRxAttributes(addonItem, ['rxTintingColorDescription']),
    )[0]
    return {
      name: getRxSubItemName(addonItem),
      priceWithTax: addonItem.variant.price.withTax,
      ...(Boolean(tintingColorDescription) && {
        color: tintingColorDescription,
      }),
    }
  })

const generateItemGroup = (id: string, isMainItem: boolean) => ({
  id,
  isMainItem,
  isRequired: true,
})

export const getMainSerializedItem = (
  groupId: string,
  variantId: number,
  shopId: number,
  addonItems: ConfiguratorStateValue[],
  quantity = 1,
): ConfiguratorSerializedMain => ({
  variantId,
  quantity,
  itemGroup: generateItemGroup(groupId, true),
  customData: {
    itemGroup: generateItemGroup(groupId, true),
    shopId,
    rxSubItemInfo: generateRxSubItemInfo(addonItems),
  },
})

export const getAddonSerializedItem = (
  groupId: string,
  variantId: number,
  shopId: number,
  quantity = 1,
): ConfiguratorSerializedAddon => ({
  variantId,
  quantity,
  itemGroup: generateItemGroup(groupId, false),
  customData: {
    itemGroup: generateItemGroup(groupId, false),
    shopId,
    hideSubItem: true,
  },
})

export const getAddonSerializedItems = (
  groupId: string,
  items: Array<ConfiguratorStateValue | undefined>,
  shopId: number,
): ConfiguratorSerializedAddon[] => {
  const result: ConfiguratorSerializedAddon[] = []
  for (const addonItem of items) {
    if (addonItem) {
      result.push(getAddonSerializedItem(groupId, addonItem.variant.id, shopId))
    }
  }
  return result
}

export const getPrescriptionValuesSerialized = async (
  rxFittingHeightPending: () => Promise<void>,
  prescriptionValues: IPrescriptionValues,
  rxFittingHeight: Ref<IFittingHeightMapped | undefined>,
): Promise<RxPrescriptionValues> => {
  try {
    await rxFittingHeightPending()
  } catch (error) {
    console.log(error)
  } finally {
    // eslint-disable-next-line no-unsafe-finally
    return {
      left: {
        sphere: prescriptionValues.sphere.left!,
        cylinder: prescriptionValues!.cylinder.left! || 0,
        axis: prescriptionValues.axis.left! || 0,
        pd: prescriptionValues.pd.left!,
        ...(rxFittingHeight.value?.hpd.left && {
          hpd: rxFittingHeight.value.hpd.left,
        }),
      },
      right: {
        sphere: prescriptionValues.sphere.right!,
        cylinder: prescriptionValues.cylinder.right! || 0,
        axis: prescriptionValues.axis.right! || 0,
        pd: prescriptionValues.pd.right!,
        ...(rxFittingHeight.value?.hpd.right && {
          hpd: rxFittingHeight.value.hpd.right,
        }),
      },
    }
  }
}

export const formatRxPrescriptionValuesDisplayData = (
  prescriptionValue: RxPrescriptionValue,
  i18n: NuxtApp['$i18n'],
  currentShop: NuxtApp['$currentShop'],
) =>
  [
    `${i18n.t(
      `osp.sight_specification_abbreviations.${'sphere'}`,
    )} ${formatPrescriptionValue(
      'sphere',
      prescriptionValue?.sphere,
      currentShop,
    )}`,
    `${i18n.t(
      `osp.sight_specification_abbreviations.${'cylinder'}`,
    )} ${formatPrescriptionValue(
      'cylinder',
      prescriptionValue?.cylinder,
      currentShop,
    )}`,
    `${i18n.t(
      `osp.sight_specification_abbreviations.${'axis'}`,
    )} ${formatPrescriptionValue(
      'axis',
      prescriptionValue?.axis,
      currentShop,
    )}`,
    `${i18n.t(
      `osp.sight_specification_abbreviations.${'pd'}`,
    )} ${formatPrescriptionValue('pd', prescriptionValue?.pd, currentShop)}`,
  ].join(' | ')

export const formatPrescriptionValue = (
  prop: FormattableLensesPrescriptionProp,
  value: number | undefined | null,
  currentShop: NuxtApp['$currentShop'] | RpcContext,
  showUnit: boolean = true,
) => {
  if (typeof value === 'undefined' || value === null || isNaN(value)) {
    return '-'
  }

  const numberFormatOptions = prescriptionFormatter[prop]

  if (!numberFormatOptions) {
    return toLocaleString(value, currentShop)
  }

  if (!showUnit) {
    return toLocaleString(value, currentShop, {
      ...numberFormatOptions,
      style: 'decimal',
    })
  }

  if (numberFormatOptions.style === 'unit') {
    return toLocaleString(value, currentShop, numberFormatOptions)
  }

  const unit = isPrescriptionProp(prop) ? prescriptionPropUnits[prop] : ''

  return [value.toLocaleString('de-DE', numberFormatOptions), unit]
    .filter(Boolean)
    .join(' ')
}

export const mapSightPrescriptionToSightData = (
  sightPrescription: IPrescriptionValues,
): RxPrescriptionValues => ({
  right: {
    sphere: sightPrescription.sphere.right || 0,
    cylinder: sightPrescription.cylinder.right || 0,
    axis: sightPrescription.axis.right || 0,
    pd: sightPrescription.pd.right || 0,
  },
  left: {
    sphere: sightPrescription.sphere.left || 0,
    cylinder: sightPrescription.cylinder.left || 0,
    axis: sightPrescription.axis.left || 0,
    pd: sightPrescription.pd.left || 0,
  },
})

export const getRxSortingValue = (
  item: Product | Variant,
  defaultValue: number = Infinity,
): number => {
  if (item?.attributes?.rxProductSorting) {
    return parseFloat(
      getAttributeValue(item.attributes, 'rxProductSorting') ||
        `${defaultValue}`,
    )
  }

  if (item?.attributes?.rxVariantSorting) {
    return parseFloat(
      getAttributeValue(item.attributes, 'rxVariantSorting') ||
        `${defaultValue}`,
    )
  }

  return defaultValue
}

export const getRxCountryIndexAvailability = (
  product: Product | Ref<Product> | undefined,
  currentShop: NuxtApp['$currentShop'],
) => {
  const p = unref(product)

  if (!p) {
    return defaultLensQualityRefs
  }

  const attributeValues =
    p.advancedAttributes?.rxCountryIndexAvailability?.values

  // Fallback if attribute is not available
  if (!Array.isArray(attributeValues) || attributeValues.length === 0) {
    const rimType = getAttributeValue(p?.attributes ?? {}, 'rimType') || ''

    return entryGlassIncompatibleRimtypes.includes(rimType || '')
      ? defaultLensQualityRefs.slice(1)
      : defaultLensQualityRefs
  }

  const country = getIsoCountry(currentShop.locale).toLowerCase()

  const result: string[] = attributeValues
    .flatMap((value) =>
      value.fieldSet.map((item) => item.map((inner) => inner.value)),
    )
    .filter(([locale]) => locale === country)
    .map(([, value]) => value?.toString() ?? '')
    .filter(
      (value) => value && Object.values(rxLensQualityRefKeys).includes(value),
    )

  return result.length ? result : defaultLensQualityRefs
}

export const filterLensQualityIndexItems = <
  T extends { referenceKey?: string },
>(
  items: Ref<T[]> | T[],
  product: Product,
  $currentShop: NuxtApp['$currentShop'],
): T[] => {
  const availableQualities = getRxCountryIndexAvailability(
    product,
    $currentShop,
  )

  return (unref(items) || []).filter((item) =>
    availableQualities.includes(item?.referenceKey || ''),
  )
}

export const checkSome = (
  values: IPrescriptionValue | undefined,
  cb: (value?: number) => boolean,
) => Boolean(values && (cb(values.left) || cb(values.right)))

export const getLensQualityRecommendationRef = (
  availableQualities: Product[],
  prescriptionValues: IPrescriptionValues,
): string | undefined => {
  let index: keyof typeof rxRecommendationMapping = FACTOR_ENTRY
  if (
    checkSome(
      prescriptionValues.sphere,
      (val) => !!val && (val <= -2.5 || val >= 2.5),
    )
  ) {
    index = FACTOR_STANDARD_PLUS
  } else if (
    checkSome(
      prescriptionValues.sphere,
      (val) => !!val && (val <= -1 || val >= 1.5),
    )
  ) {
    index = FACTOR_STANDARD
  }

  const recommendationQualities = rxRecommendationMapping[index]

  return availableQualities.find((product) =>
    recommendationQualities.includes(product.referenceKey || ''),
  )?.referenceKey
}

export const getRxDisplayData = (
  i18n: NuxtApp['$i18n'],
  $currentShop: NuxtApp['$currentShop'],
  colors: string,
  frameSizes: string,
  prescriptionValuesSerialized?: RxPrescriptionValues,
) => {
  const displayData: RxBasketItemDisplayData = {}
  if (colors) {
    displayData[RxDisplayDataAttributes.colorAttribute] = {
      key: 'colors',
      label: i18n.t('pdp.colors_heading'),
      value: colors,
    }
  }

  if (frameSizes) {
    displayData[RxDisplayDataAttributes.sizeAttribute] = {
      key: 'frameSizes',
      label: i18n.t('basket_card.size_label'),
      value: frameSizes,
    }
  }

  if (prescriptionValuesSerialized) {
    displayData[RxDisplayDataAttributes.prescriptionValuesLeftAttribute] = {
      key: 'prescriptionValuesLeft',
      label: i18n.t('osp.lenses.left'),
      value: formatRxPrescriptionValuesDisplayData(
        prescriptionValuesSerialized.left,
        i18n,
        $currentShop,
      ),
    }

    displayData[RxDisplayDataAttributes.prescriptionValuesRightAttribute] = {
      key: 'prescriptionValuesRight',
      label: i18n.t('osp.lenses.right'),
      value: formatRxPrescriptionValuesDisplayData(
        prescriptionValuesSerialized.right,
        i18n,
        $currentShop,
      ),
    }
  }

  return displayData
}

export const getConfStepIndexByMasterKey = (
  masterKey: RxConfMasterKeys | 'editPrescription',
  hasPrescriptionValues: boolean,
) => {
  const steps: Record<RxConfMasterKeys, number> = {
    [RxConfMasterKeys.freeService]: 0,
    [RxConfMasterKeys.lensTypes]: 0,
    [RxConfMasterKeys.lensQuality]: hasPrescriptionValues ? 2 : 1,
    [RxConfMasterKeys.tintingTypes]: hasPrescriptionValues ? 3 : 2,
  }

  return masterKey === 'editPrescription' ? 1 : steps[masterKey]
}

export const isClearType = (referenceKey: string) =>
  [
    rxTintingRefKeys.CLEAR,
    rxTintingRefKeys.ENTRY_NOCOAT_CLEAR,
    rxTintingRefKeys.ENTRY_CLEAR,
    rxTintingRefKeys.STANDARD_CLEAR,
    rxTintingRefKeys.STANDARD_PLUS_CLEAR,
    rxTintingRefKeys.PREMIUM_CLEAR,
  ].includes(referenceKey)

interface RxHistoryStateSteps {
  rxConfiguratorStep?: number
  rxPrescriptionStep?: number
  virtualHistoryStep?: number
}

export const pushVirtualHistoryStep = (rxSteps: RxHistoryStateSteps): void => {
  rxSteps.virtualHistoryStep =
    (rxSteps.virtualHistoryStep || history.state?.virtualHistoryStep || 0) + 1

  history.pushState(
    {
      ...history.state,
      ...rxSteps,
    },
    '',
  )
}

export const clearVirtualHistory = (): void => {
  if (!history.state?.virtualHistoryStep) {
    return
  }
  history.go(-(history.state?.virtualHistoryStep || 1))

  const state = { ...history.state }
  delete state.rxConfiguratorStep
  delete state.rxPrescriptionStep
  delete state.virtualHistoryStep

  history.replaceState(state, '')
}

export const formatPrescription = (
  prescription: CustomerOrderResponse,
): RxPrescriptionValues => {
  return {
    right: {
      axis: prescription.right.axis,
      cylinder: prescription.right.cylinder,
      pd: prescription.right.pupillaryDistance,
      sphere: prescription.right.sphere,
    },
    left: {
      axis: prescription.left.axis,
      cylinder: prescription.left.cylinder,
      pd: prescription.left.pupillaryDistance,
      sphere: prescription.left.sphere,
    },
  }
}

export const formatPrescriptionToRxConfigurator = (
  prescription: RxPrescriptionValues,
): IPrescriptionValues => {
  return {
    sphere: {
      left: prescription.left.sphere,
      right: prescription.right.sphere,
    },
    cylinder: {
      left: prescription.left.cylinder,
      right: prescription.right.cylinder,
    },
    axis: {
      left: prescription.left.axis,
      right: prescription.right.axis,
    },
    pd: {
      left: prescription.left.pd,
      right: prescription.right.pd,
    },
  }
}
