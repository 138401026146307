import type { AsyncDataOptions } from '#app'
import type {
  RpcContext,
  RpcMethodName,
  RpcMethodParameters,
  RpcMethodReturnType,
} from '@scayle/storefront-nuxt'
import { rpcCall } from '@scayle/storefront-nuxt'
import { hash, objectHash } from 'ohash'

export const useAsyncRpc = <
  N extends RpcMethodName,
  P extends RpcMethodParameters<N>,
  TResult extends Exclude<Awaited<RpcMethodReturnType<N>>, Response>,
  TParams = P extends RpcContext ? undefined : P,
>(
  method: N,
  params?: MaybeRefOrGetter<TParams>,
  options: AsyncDataOptions<TResult | undefined> = {},
) => {
  const nuxtApp = useNuxtApp()
  const currentShop = useCurrentShop()
  const { logger } = useLogging()

  const wrappedCall = rpcCall(nuxtApp, method, currentShop.value)
  const key = `${currentShop.value?.shopId}:${method}:${hash(objectHash(toValue(params)))}`

  return useAsyncData(
    key,
    async () => {
      try {
        return await wrappedCall(toValue(params) as P)
      } catch (error) {
        logger.error(error, {
          why: `rpc:${method}`,
          where: 'useAsyncRpc.ts',
        })
      }
    },
    {
      dedupe: 'defer',
      ...options,
    },
  )
}
