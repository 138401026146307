/* eslint sonarjs/cognitive-complexity: 1 */

import {
  getFirstAttributeValue,
  slugify,
  type BrandOrCategorySuggestion,
  type Category,
  type CategorySearchSuggestion,
  type Product,
  type ProductSuggestion,
  type Value,
} from '@scayle/storefront-nuxt'
import baseSlugify from 'slugify'
import { PDPComponentType } from '../types/pdp'
import type { NavigateToOptions } from '#app/composables/router'
import type { RouteLocationRaw } from '#vue-router'

const slugifyOptions = { lower: true, remove: /[/*+~.()'"!:@]/g }

export default () => {
  const { $fimLocalePath } = useNuxtApp()

  const localizedNavigateTo = async (
    route: RouteLocationRaw,
    options?: NavigateToOptions,
  ) => {
    const routePath = $fimLocalePath(route)
    return await navigateTo(routePath, options)
  }

  const getOrderProductDetailRoute = (
    product: OrderProduct,
    id?: number,
  ): RouteLocationRaw => {
    const name = product.attributes.name.label
    return $fimLocalePath({
      name: 'p-slug',
      params: {
        slug: `${slugify(name)}-${id || product.id}`,
      },
    })
  }

  const getProductDetailPath = (product: Product, id?: number) => {
    const name = getFirstAttributeValue(product.attributes, 'name')?.label
    return $fimLocalePath(`/p/${slugify(name)}-${id || product.id}`)
  }

  const getProductDetailRoute = (
    product: Product,
    query: Record<string, string | number | undefined> = {},
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    const pdpComponent = getPDPComponentType(product)
    const isGlasses =
      pdpComponent === PDPComponentType.glasses ||
      pdpComponent === PDPComponentType.correctionalGlasses
    const isContactLens = pdpComponent === PDPComponentType.contactLens
    const isCareProducts = pdpComponent === PDPComponentType.care
    const isMerch = pdpComponent === PDPComponentType.merch

    const normalizedQuery: Record<string, string | undefined> =
      Object.fromEntries(
        Object.entries(query).map(([key, value]) => {
          if (typeof value === 'number') {
            return [key, `${value}`]
          }
          return [key, value]
        }),
      )

    // Glasses and Merch should include variantId in URL only if user selects a variant
    // https://aboutyou.atlassian.net/browse/SCFIM-1031
    const variantsSortedByPrice = (
      product.variants ? [...(product?.variants ?? [])] : []
    ).sort((a, b) => a.price.withTax - b.price.withTax)

    // Add cheapest variantId automatically to URL for some categories
    if (
      !normalizedQuery.variantid &&
      !isGlasses &&
      !isMerch &&
      variantsSortedByPrice?.length
    ) {
      normalizedQuery.variantid = String(variantsSortedByPrice[0].id)
    }

    // Never include cheapest variantId in URL for other categories
    if (
      normalizedQuery.variantid &&
      (isGlasses || isMerch) &&
      variantsSortedByPrice?.length &&
      normalizedQuery.variantid === String(variantsSortedByPrice[0].id)
    ) {
      normalizedQuery.variantid = undefined
    }

    // variantIdL & variantIdR for contact lenses
    if (isContactLens) {
      normalizedQuery.variantid = undefined
    }

    const frameColorValues = product.attributes?.frameColor?.values as Value
    const manufacturerColorCodeValues = product.attributes
      ?.manufacturerColorCode?.values as Value
    const lensBaseColorValues = product.attributes?.lensBaseColor
      ?.values as Value[]
    const numberOfLensesValues = product.attributes?.numberOfLenses
      ?.values as Value

    const careProductVariant = product.variants?.find(
      (it) => it.id === Number(normalizedQuery.variantid),
    )

    const packingTypeDescription = careProductVariant?.attributes
      ?.packingTypeDescription?.values as Value

    const glassessAttributesSlug =
      isGlasses &&
      baseSlugify(
        [
          manufacturerColorCodeValues && manufacturerColorCodeValues?.value,
          frameColorValues && frameColorValues?.label,
          lensBaseColorValues && lensBaseColorValues[0]?.label,
        ].join('-'),
        slugifyOptions,
      )

    const contactLensSlug =
      isContactLens &&
      baseSlugify(
        (numberOfLensesValues && numberOfLensesValues?.value) ?? '',
        slugifyOptions,
      )

    const careProductsSlug =
      isCareProducts &&
      baseSlugify(
        (packingTypeDescription && packingTypeDescription.value) || '',
        slugifyOptions,
      )

    const slug = [
      baseSlugify(
        getFirstAttributeValue(product.attributes, 'name')?.label ?? '',
        slugifyOptions,
      ),
      glassessAttributesSlug,
      contactLensSlug,
      careProductsSlug,
      product.id,
    ].filter(Boolean)

    return {
      ...routeList.product,
      params: { slug: slug.join('-') },
      query: normalizedQuery,
    }
  }

  const getSearchRoute = (term: string): RouteLocationRaw => {
    return $fimLocalePath({
      name: 'search',
      query: { term },
    })
  }

  const buildCategorySuggestionRoute = ({
    categorySuggestion,
  }: CategorySearchSuggestion): RouteLocationRaw => {
    const { category, filters } = categorySuggestion
    return {
      path: buildCategoryPath(category),
      query: buildFiltersQuery(filters),
    }
  }

  const buildCategoryPath = ({
    id,
    path,
  }: Category | { id: number; path: string }): string => {
    return $fimLocalePath(path)
  }

  const getSearchSuggestionPath = (
    suggestion: ProductSuggestion | BrandOrCategorySuggestion,
  ) => {
    if (!suggestion) {
      return
    }

    if ('product' in suggestion) {
      return getProductDetailPath(suggestion.product)
    }

    const { category, brand } = suggestion
    const route =
      category && brand
        ? `${getCategoryPath(category)}?brand=${brand?.id}`
        : getCategoryPath(category)

    return route && $fimLocalePath(route)
  }

  const getOrderDetailsRoute = (id: number): RouteLocationRaw => {
    return $fimLocalePath({
      name: routeList.orderDetail.name,
      params: { id },
    })
  }

  return {
    getOrderDetailsRoute,
    getOrderProductDetailRoute,
    getProductDetailRoute,
    getSearchRoute,
    getSearchSuggestionPath,
    localizedNavigateTo,
    buildCategorySuggestionRoute,
    buildCategoryPath,
  }
}
