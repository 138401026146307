export const PAGE_TYPE_DEFAULT_PLP = 'default-plp' as const
export const PAGE_TYPE_LENSES_PLP = 'lenses-plp' as const
export const PAGE_TYPE_LENSES_CARE_PLP = 'lenses-care-plp' as const
export const PAGE_TYPE_CONTENT_PAGES = 'content-pages' as const

export type PlpPageType =
  | typeof PAGE_TYPE_DEFAULT_PLP
  | typeof PAGE_TYPE_LENSES_PLP
  | typeof PAGE_TYPE_LENSES_CARE_PLP
  | typeof PAGE_TYPE_CONTENT_PAGES

export type PlpPageTypeMapping = Record<string, PlpPageType>

export const PRIVACY_PAGE_SLUGS = [
  'datenschutz',
  'ochronadanych',
  'ochrana-udaju',
  'protectiondesdonnees',
  'protezionedeidati',
]
