import type { Product, Variant } from '@scayle/storefront-nuxt'
import { useSessionStorage } from '@vueuse/core'
import useUiState from './ui/useUiState'
import { useToast } from '~/composables/useToast'
import type { IStore } from '~/rpcMethods/stores'
import { Action } from '~/plugins/toast'

export type RopoItem = {
  product: Product
  variant: Variant
}

export const SELECTED_ROPO_STORE_QUERY_PARAM_NAME = 'branch-id'
export const EMPTY_ROPO_ITEM_VALUE = null
export type RopoBasketState = Array<RopoItem | typeof EMPTY_ROPO_ITEM_VALUE>

const ropoBasketItems = useSessionStorage(
  'ropoBasketItems',
  [] as (RopoItem | null)[],
)
const selectedRopoStore = useSessionStorage('selectedRopoStore', {} as IStore)
const editingIndex: Ref<number | undefined> = ref(undefined)

export const useRopoBasketState = () => {
  const { $localePath, $i18n, $featureToggle } = useNuxtApp()
  const { alert } = useToast()
  const { ropoMaxNumberOfArticles, isRopoEnabled, ropoConfig } = $featureToggle

  const { openRopoOverlay } = useUiState()
  const router = useRouter()

  const numberOfRopoArticles = computed(
    () => ropoBasketItems.value.filter((item) => item !== null).length,
  )

  const isRopoBasketFull = computed(
    () => numberOfRopoArticles.value >= ropoMaxNumberOfArticles.value,
  )

  const isRopoBasketValid = computed(() => Boolean(numberOfRopoArticles.value))

  // Initialize ropoBasketItems with empty items
  if (ropoBasketItems.value.length === 0) {
    ropoBasketItems.value = Array(ropoMaxNumberOfArticles.value).fill(
      EMPTY_ROPO_ITEM_VALUE,
    )
  }

  const setEditingIndex = (index: number) => {
    editingIndex.value = index
  }

  const addToRopoBasket = (ropoItem: RopoItem) => {
    if (
      ropoBasketItems.value.find(
        (item) =>
          item !== null &&
          item.product.id === ropoItem.product.id &&
          item.variant.id === ropoItem.variant.id,
      )
    ) {
      return { success: false as const, error: 'duplicate-item' as const }
    }
    if (isRopoBasketFull.value) {
      return { success: false as const, error: 'basket-full' as const }
    }

    // If user starts on PLP instead of Basket we have no editingIndex so then
    // default to first empty slot
    const index =
      editingIndex.value !== undefined
        ? editingIndex.value
        : ropoBasketItems.value.findIndex((item) => item === null)
    ropoBasketItems.value.splice(index, 1, ropoItem)
    editingIndex.value = undefined
    return { success: true as const }
  }

  const addProductToRopoBasket = (
    product: Product | null,
    variant?: Variant,
  ) => {
    const selectedVariant = variant || (product && getCheapestVariant(product))
    if (!product || !selectedVariant) {
      alert($i18n.t('ropo.add_to_basket.error'), Action.error)
      return
    }

    const addedResult = addToRopoBasket({
      product,
      variant: selectedVariant,
    })

    if (addedResult.success === true) {
      alert(
        $i18n.t('ropo.add_to_basket.success'),
        Action.route,
        routeList.ropoBasket.path,
      )
    } else if (addedResult.error === 'duplicate-item') {
      alert(
        $i18n.t('ropo.add_to_basket.duplicate_found'),
        Action.route,
        routeList.ropoBasket.path,
      )
    } else if (addedResult.error === 'basket-full') {
      openRopoOverlay(getProductName(product, $i18n.t('wishlist.product')))
    }

    return addedResult
  }

  const isIndexInBounds = (index: number) => {
    if (index > ropoMaxNumberOfArticles.value - 1 && index < 0) {
      console.error('[ROPOBASKET]: index out of bounds')
      return false
    }
    return true
  }

  const removeFromRopoBasket = (index: number) => {
    if (isIndexInBounds(index)) {
      ropoBasketItems.value.splice(index, 1, EMPTY_ROPO_ITEM_VALUE)
      return true
    }
    return false
  }

  const getSerializedRopoBasket = () => {
    const ids = ropoBasketItems.value
      .filter((item) => item !== null)
      .map((item: any) => ({
        articleId: item.product.id,
        variantId: item.variant.id,
      }))
    return btoa(JSON.stringify(ids))
  }

  // Example: https://termine.fielmann.de/find-branch?service=GL_CA&branch-id=001-0167&ropo-ids=xyz
  // branch-id optional
  const getRopoSubmitUrl = (appointmentDomain: string, baseFolder?: string) => {
    if (!ropoBasketItems.value.length) {
      console.error(
        '[ROPOBASKET]: trying to get Ropo Submit URL while no articles set',
      )
    }

    const url = normalizeUrl([baseFolder, 'find-branch'], appointmentDomain)
    const serializedRopoIds = getSerializedRopoBasket()
    url.searchParams.append('ropo-ids', serializedRopoIds)
    url.searchParams.append('service', ServiceCategoryDto.GL_CR)
    if (selectedRopoStore?.value?.id) {
      url.searchParams.append(
        SELECTED_ROPO_STORE_QUERY_PARAM_NAME,
        selectedRopoStore.value.recordsetID.toString(),
      )
    }

    return url.toString()
  }

  const setSelectedRopoStore = (store: IStore) => {
    selectedRopoStore.value = store
  }

  const gotoRopoBasket = (store: IStore) => {
    setSelectedRopoStore(store)
    router.push($localePath(routeList.ropoBasket))
  }

  const gotoRopoPlp = (index: number) => {
    setEditingIndex(index)
    router.push($localePath(routeList.ropoPlp))
  }

  const isRopoSupported = (product: Product | null | undefined) => {
    const categoryPaths =
      product?.categories?.flat()?.map((category) => category.categoryUrl) ?? []

    if (
      !isRopoEnabled.value ||
      !ropoConfig.value?.categoryPath ||
      !categoryPaths?.length
    ) {
      return false
    }

    return categoryPaths.includes(ropoConfig.value.categoryPath)
  }

  return {
    ropoBasketItems,
    addToRopoBasket,
    addProductToRopoBasket,
    isRopoSupported,
    removeFromRopoBasket,
    isRopoBasketFull,
    numberOfRopoArticles,
    isRopoBasketValid,
    getSerializedRopoBasket,
    selectedRopoStore,
    setSelectedRopoStore,
    getRopoSubmitUrl,
    gotoRopoBasket,
    gotoRopoPlp,
    setEditingIndex,
  }
}
