const useButtonClickEvents = (
  track: (
    event: FimTrackingEvent,
    payload: FimTrackingPayload,
    options?: MapToTrackingPayloadOptions,
  ) => any,
) => {
  return {
    trackButtonClick: (element: HTMLButtonElement) => {
      track('FielmannBasic_ButtonClick', {
        element,
      })
    },
  }
}

export default useButtonClickEvents
