<template>
  <div class="flex justify-end justify-self-stretch">
    <RopoBasketHeaderIcon
      v-if="isRopoEnabled"
      data-testid="ropo-basket-main-menu-link"
    ></RopoBasketHeaderIcon>
    <FimLink
      v-else
      :to="localePath({ name: routeList.stores.name })"
      size="sm"
      :aria-label="$t('footer.store_finder.heading')"
      class="px-2"
      data-testid="store-finder-main-menu-link"
      data-tracking-id="navigation_appointment"
      data-tracking-label="Appointment"
      @click="onLinkClick"
    >
      <LazyIconFielmannCalendar class="size-7 stroke-[1.5]" />
    </FimLink>

    <FimLink
      class="header-main-menu__user px-2"
      :aria-label="
        isLoggedIn ? $t('myaccount.menu_title') : $t('global.sign_in')
      "
      :to="
        isLoggedIn
          ? localePath(routeList.account.name)
          : localePath({ name: routeList.signin.name })
      "
      size="sm"
      data-testid="header-account"
      data-tracking-id="navigation_account"
      data-tracking-label="Account"
      @click="onLinkClick"
    >
      <LazyIconFielmannUserVerified
        v-if="isLoggedIn"
        class="mb-1 size-7 stroke-[1.5]"
      />
      <LazyIconFielmannUser v-else class="size-7 stroke-[1.5]" />
    </FimLink>

    <WishlistLink />
    <HeaderBasketPopover :bold-on-active="false" />
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useFeatureToggles } from '~/composables/useFeatureToggles'

const localePath = useFimLocalePath()
const { isLoggedIn, fetch: fetchUser } = await useUser({
  immediate: false,
  lazy: true,
})
const { isRopoEnabled } = await useFeatureToggles()
const { trackMenuIconClick } = await useTrackingEvents()

const onLinkClick = (event: MouseEvent) => {
  trackMenuIconClick({ element: event.target as HTMLAnchorElement })
}

onMounted(() => {
  fetchUser()
})
</script>

<style scoped>
@media (width <=374px) {
  /* stylelint-disable-next-line selector-class-pattern */
  .header-main-menu__user {
    display: none;
  }
}
</style>
