import { default as _91_46_46_46category_93wajVM5mVB2Meta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/[...category].vue?macro=true";
import { default as appointmentsnQe25JyT3jMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/appointments.vue?macro=true";
import { default as _91id_93vGVk2DH5NXMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/branch-order/[id].vue?macro=true";
import { default as indexN3BTxtD5udMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/branch-order/index.vue?macro=true";
import { default as index9ANNBGI2VAMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/index.vue?macro=true";
import { default as _91id_93kha0WvJKaZMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/orders/[id].vue?macro=true";
import { default as indexIGobx1FVypMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/orders/index.vue?macro=true";
import { default as subscription_45cancellationsEsG86PvuSjMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/subscription-cancellations.vue?macro=true";
import { default as subscriptionsd7fxEpILLTMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/subscriptions.vue?macro=true";
import { default as userKpi6tdnpgTMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/user.vue?macro=true";
import { default as basketmMz9qgirJNMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/basket.vue?macro=true";
import { default as checkout2B6rf1bgsiMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/checkout.vue?macro=true";
import { default as indexETRsKDyRDTMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/index.vue?macro=true";
import { default as _91id_93p7qzOm8Z4xMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/[city]/[id].vue?macro=true";
import { default as indexrXysU5diG6Meta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/index.vue?macro=true";
import { default as _91city_93h5pLR7DvYsMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/stadt/[county]/[city].vue?macro=true";
import { default as _91slug_93S5DUCOVhWyMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/overlays/[group]/[slug].vue?macro=true";
import { default as _91slug_93rWFJWKIGJdMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/[slug].vue?macro=true";
import { default as cleaning_45fluid_45pdpd4pz91It7uMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/cleaning-fluid-pdp.vue?macro=true";
import { default as contact_45lens_45pdpIA5RwyTHKLMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/contact-lens-pdp.vue?macro=true";
import { default as glasses_45pdprxs24d0dlyMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/glasses-pdp.vue?macro=true";
import { default as merch_45pdppSfWTlodf1Meta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/merch-pdp.vue?macro=true";
import { default as rx_45glasses_45pdpSBAAcBoEqFMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/rx-glasses-pdp.vue?macro=true";
import { default as ropo_45basketmTSzJt8DH9Meta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/ropo-basket.vue?macro=true";
import { default as ropo_45plpVOtPSZhmJtMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/ropo-plp.vue?macro=true";
import { default as searchFHwENevToFMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/search.vue?macro=true";
import { default as auftragsstatusVXzmIvJBbHMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/auftragsstatus.vue?macro=true";
import { default as gebrauchsanweisungenakdmtwld5eMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/gebrauchsanweisungen.vue?macro=true";
import { default as konformitaetserklaerungenoPVdGOzYRfMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/konformitaetserklaerungen.vue?macro=true";
import { default as signinHDEBWpeZkmMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/signin.vue?macro=true";
import { default as successZCNJSmmWCtMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/success.vue?macro=true";
import { default as wishlists2nZdBkxyIMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/wishlist.vue?macro=true";
export default [
  {
    name: "category___de-at",
    path: "/:category(.*)*/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/[...category].vue").then(m => m.default || m)
  },
  {
    name: "category___de-de",
    path: "/:category(.*)*/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/[...category].vue").then(m => m.default || m)
  },
  {
    name: "category___cs-cz",
    path: "/:category(.*)*/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/[...category].vue").then(m => m.default || m)
  },
  {
    name: "category___pl-pl",
    path: "/:category(.*)*/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/[...category].vue").then(m => m.default || m)
  },
  {
    name: "category___de-ch",
    path: "/:category(.*)*/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/[...category].vue").then(m => m.default || m)
  },
  {
    name: "category___fr-ch",
    path: "/:category(.*)*/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/[...category].vue").then(m => m.default || m)
  },
  {
    name: "category___it-ch",
    path: "/:category(.*)*/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/[...category].vue").then(m => m.default || m)
  },
  {
    name: "account-appointments___de-at",
    path: "/account/appointments/",
    meta: appointmentsnQe25JyT3jMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/appointments.vue").then(m => m.default || m)
  },
  {
    name: "account-appointments___de-de",
    path: "/account/appointments/",
    meta: appointmentsnQe25JyT3jMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/appointments.vue").then(m => m.default || m)
  },
  {
    name: "account-appointments___cs-cz",
    path: "/account/appointments/",
    meta: appointmentsnQe25JyT3jMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/appointments.vue").then(m => m.default || m)
  },
  {
    name: "account-appointments___pl-pl",
    path: "/account/appointments/",
    meta: appointmentsnQe25JyT3jMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/appointments.vue").then(m => m.default || m)
  },
  {
    name: "account-appointments___de-ch",
    path: "/account/appointments/",
    meta: appointmentsnQe25JyT3jMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/appointments.vue").then(m => m.default || m)
  },
  {
    name: "account-appointments___fr-ch",
    path: "/account/appointments/",
    meta: appointmentsnQe25JyT3jMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/appointments.vue").then(m => m.default || m)
  },
  {
    name: "account-appointments___it-ch",
    path: "/account/appointments/",
    meta: appointmentsnQe25JyT3jMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/appointments.vue").then(m => m.default || m)
  },
  {
    name: "account-branch-order-id___de-at",
    path: "/account/branch-order/:id()/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/branch-order/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-branch-order-id___de-de",
    path: "/account/branch-order/:id()/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/branch-order/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-branch-order-id___cs-cz",
    path: "/account/branch-order/:id()/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/branch-order/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-branch-order-id___pl-pl",
    path: "/account/branch-order/:id()/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/branch-order/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-branch-order-id___de-ch",
    path: "/account/branch-order/:id()/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/branch-order/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-branch-order-id___fr-ch",
    path: "/account/branch-order/:id()/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/branch-order/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-branch-order-id___it-ch",
    path: "/account/branch-order/:id()/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/branch-order/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-branch-order___de-at",
    path: "/account/branch-order/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/branch-order/index.vue").then(m => m.default || m)
  },
  {
    name: "account-branch-order___de-de",
    path: "/account/branch-order/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/branch-order/index.vue").then(m => m.default || m)
  },
  {
    name: "account-branch-order___cs-cz",
    path: "/account/branch-order/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/branch-order/index.vue").then(m => m.default || m)
  },
  {
    name: "account-branch-order___pl-pl",
    path: "/account/branch-order/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/branch-order/index.vue").then(m => m.default || m)
  },
  {
    name: "account-branch-order___de-ch",
    path: "/account/branch-order/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/branch-order/index.vue").then(m => m.default || m)
  },
  {
    name: "account-branch-order___fr-ch",
    path: "/account/branch-order/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/branch-order/index.vue").then(m => m.default || m)
  },
  {
    name: "account-branch-order___it-ch",
    path: "/account/branch-order/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/branch-order/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de-at",
    path: "/account/",
    meta: index9ANNBGI2VAMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de-de",
    path: "/account/",
    meta: index9ANNBGI2VAMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___cs-cz",
    path: "/account/",
    meta: index9ANNBGI2VAMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___pl-pl",
    path: "/account/",
    meta: index9ANNBGI2VAMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de-ch",
    path: "/account/",
    meta: index9ANNBGI2VAMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___fr-ch",
    path: "/account/",
    meta: index9ANNBGI2VAMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___it-ch",
    path: "/account/",
    meta: index9ANNBGI2VAMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-id___de-at",
    path: "/account/orders/:id()/",
    meta: _91id_93kha0WvJKaZMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-orders-id___de-de",
    path: "/account/orders/:id()/",
    meta: _91id_93kha0WvJKaZMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-orders-id___cs-cz",
    path: "/account/orders/:id()/",
    meta: _91id_93kha0WvJKaZMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-orders-id___pl-pl",
    path: "/account/orders/:id()/",
    meta: _91id_93kha0WvJKaZMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-orders-id___de-ch",
    path: "/account/orders/:id()/",
    meta: _91id_93kha0WvJKaZMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-orders-id___fr-ch",
    path: "/account/orders/:id()/",
    meta: _91id_93kha0WvJKaZMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-orders-id___it-ch",
    path: "/account/orders/:id()/",
    meta: _91id_93kha0WvJKaZMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-orders___de-at",
    path: "/account/orders/",
    meta: indexIGobx1FVypMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders___de-de",
    path: "/account/orders/",
    meta: indexIGobx1FVypMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders___cs-cz",
    path: "/account/orders/",
    meta: indexIGobx1FVypMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders___pl-pl",
    path: "/account/orders/",
    meta: indexIGobx1FVypMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders___de-ch",
    path: "/account/orders/",
    meta: indexIGobx1FVypMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders___fr-ch",
    path: "/account/orders/",
    meta: indexIGobx1FVypMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders___it-ch",
    path: "/account/orders/",
    meta: indexIGobx1FVypMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-subscription-cancellations___de-at",
    path: "/account/subscription-cancellations/",
    meta: subscription_45cancellationsEsG86PvuSjMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/subscription-cancellations.vue").then(m => m.default || m)
  },
  {
    name: "account-subscription-cancellations___de-de",
    path: "/account/subscription-cancellations/",
    meta: subscription_45cancellationsEsG86PvuSjMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/subscription-cancellations.vue").then(m => m.default || m)
  },
  {
    name: "account-subscription-cancellations___cs-cz",
    path: "/account/subscription-cancellations/",
    meta: subscription_45cancellationsEsG86PvuSjMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/subscription-cancellations.vue").then(m => m.default || m)
  },
  {
    name: "account-subscription-cancellations___pl-pl",
    path: "/account/subscription-cancellations/",
    meta: subscription_45cancellationsEsG86PvuSjMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/subscription-cancellations.vue").then(m => m.default || m)
  },
  {
    name: "account-subscription-cancellations___de-ch",
    path: "/account/subscription-cancellations/",
    meta: subscription_45cancellationsEsG86PvuSjMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/subscription-cancellations.vue").then(m => m.default || m)
  },
  {
    name: "account-subscription-cancellations___fr-ch",
    path: "/account/subscription-cancellations/",
    meta: subscription_45cancellationsEsG86PvuSjMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/subscription-cancellations.vue").then(m => m.default || m)
  },
  {
    name: "account-subscription-cancellations___it-ch",
    path: "/account/subscription-cancellations/",
    meta: subscription_45cancellationsEsG86PvuSjMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/subscription-cancellations.vue").then(m => m.default || m)
  },
  {
    name: "account-subscriptions___de-at",
    path: "/account/subscriptions/",
    meta: subscriptionsd7fxEpILLTMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/subscriptions.vue").then(m => m.default || m)
  },
  {
    name: "account-subscriptions___de-de",
    path: "/account/subscriptions/",
    meta: subscriptionsd7fxEpILLTMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/subscriptions.vue").then(m => m.default || m)
  },
  {
    name: "account-subscriptions___cs-cz",
    path: "/account/subscriptions/",
    meta: subscriptionsd7fxEpILLTMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/subscriptions.vue").then(m => m.default || m)
  },
  {
    name: "account-subscriptions___pl-pl",
    path: "/account/subscriptions/",
    meta: subscriptionsd7fxEpILLTMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/subscriptions.vue").then(m => m.default || m)
  },
  {
    name: "account-subscriptions___de-ch",
    path: "/account/subscriptions/",
    meta: subscriptionsd7fxEpILLTMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/subscriptions.vue").then(m => m.default || m)
  },
  {
    name: "account-subscriptions___fr-ch",
    path: "/account/subscriptions/",
    meta: subscriptionsd7fxEpILLTMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/subscriptions.vue").then(m => m.default || m)
  },
  {
    name: "account-subscriptions___it-ch",
    path: "/account/subscriptions/",
    meta: subscriptionsd7fxEpILLTMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/subscriptions.vue").then(m => m.default || m)
  },
  {
    name: "account-user___de-at",
    path: "/account/user/",
    meta: userKpi6tdnpgTMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/user.vue").then(m => m.default || m)
  },
  {
    name: "account-user___de-de",
    path: "/account/user/",
    meta: userKpi6tdnpgTMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/user.vue").then(m => m.default || m)
  },
  {
    name: "account-user___cs-cz",
    path: "/account/user/",
    meta: userKpi6tdnpgTMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/user.vue").then(m => m.default || m)
  },
  {
    name: "account-user___pl-pl",
    path: "/account/user/",
    meta: userKpi6tdnpgTMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/user.vue").then(m => m.default || m)
  },
  {
    name: "account-user___de-ch",
    path: "/account/user/",
    meta: userKpi6tdnpgTMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/user.vue").then(m => m.default || m)
  },
  {
    name: "account-user___fr-ch",
    path: "/account/user/",
    meta: userKpi6tdnpgTMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/user.vue").then(m => m.default || m)
  },
  {
    name: "account-user___it-ch",
    path: "/account/user/",
    meta: userKpi6tdnpgTMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/user.vue").then(m => m.default || m)
  },
  {
    name: "basket___de-at",
    path: "/basket/",
    meta: basketmMz9qgirJNMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/basket.vue").then(m => m.default || m)
  },
  {
    name: "basket___de-de",
    path: "/basket/",
    meta: basketmMz9qgirJNMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/basket.vue").then(m => m.default || m)
  },
  {
    name: "basket___cs-cz",
    path: "/basket/",
    meta: basketmMz9qgirJNMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/basket.vue").then(m => m.default || m)
  },
  {
    name: "basket___pl-pl",
    path: "/basket/",
    meta: basketmMz9qgirJNMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/basket.vue").then(m => m.default || m)
  },
  {
    name: "basket___de-ch",
    path: "/basket/",
    meta: basketmMz9qgirJNMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/basket.vue").then(m => m.default || m)
  },
  {
    name: "basket___fr-ch",
    path: "/basket/",
    meta: basketmMz9qgirJNMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/basket.vue").then(m => m.default || m)
  },
  {
    name: "basket___it-ch",
    path: "/basket/",
    meta: basketmMz9qgirJNMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/basket.vue").then(m => m.default || m)
  },
  {
    name: "checkout___de-at",
    path: "/checkout/",
    meta: checkout2B6rf1bgsiMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: "checkout___de-de",
    path: "/checkout/",
    meta: checkout2B6rf1bgsiMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: "checkout___cs-cz",
    path: "/checkout/",
    meta: checkout2B6rf1bgsiMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: "checkout___pl-pl",
    path: "/checkout/",
    meta: checkout2B6rf1bgsiMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: "checkout___de-ch",
    path: "/checkout/",
    meta: checkout2B6rf1bgsiMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: "checkout___fr-ch",
    path: "/checkout/",
    meta: checkout2B6rf1bgsiMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: "checkout___it-ch",
    path: "/checkout/",
    meta: checkout2B6rf1bgsiMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: "index___de-at",
    path: "/",
    meta: indexETRsKDyRDTMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de-de",
    path: "/",
    meta: indexETRsKDyRDTMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___cs-cz",
    path: "/",
    meta: indexETRsKDyRDTMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___pl-pl",
    path: "/",
    meta: indexETRsKDyRDTMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de-ch",
    path: "/",
    meta: indexETRsKDyRDTMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr-ch",
    path: "/",
    meta: indexETRsKDyRDTMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___it-ch",
    path: "/",
    meta: indexETRsKDyRDTMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "niederlassungen-city-id___de-at",
    path: "/niederlassungen/:city()/:id()/",
    meta: _91id_93p7qzOm8Z4xMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/[city]/[id].vue").then(m => m.default || m)
  },
  {
    name: "niederlassungen-city-id___de-de",
    path: "/niederlassungen/:city()/:id()/",
    meta: _91id_93p7qzOm8Z4xMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/[city]/[id].vue").then(m => m.default || m)
  },
  {
    name: "niederlassungen-city-id___cs-cz",
    path: "/pobocky/:city()/:id()/",
    meta: _91id_93p7qzOm8Z4xMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/[city]/[id].vue").then(m => m.default || m)
  },
  {
    name: "niederlassungen-city-id___pl-pl",
    path: "/salony/:city()/:id()/",
    meta: _91id_93p7qzOm8Z4xMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/[city]/[id].vue").then(m => m.default || m)
  },
  {
    name: "niederlassungen-city-id___de-ch",
    path: "/niederlassungen/:city()/:id()/",
    meta: _91id_93p7qzOm8Z4xMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/[city]/[id].vue").then(m => m.default || m)
  },
  {
    name: "niederlassungen-city-id___fr-ch",
    path: "/succursales/:city()/:id()/",
    meta: _91id_93p7qzOm8Z4xMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/[city]/[id].vue").then(m => m.default || m)
  },
  {
    name: "niederlassungen-city-id___it-ch",
    path: "/filiali/:city()/:id()/",
    meta: _91id_93p7qzOm8Z4xMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/[city]/[id].vue").then(m => m.default || m)
  },
  {
    name: "niederlassungen___de-at",
    path: "/niederlassungen/",
    meta: indexrXysU5diG6Meta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/index.vue").then(m => m.default || m)
  },
  {
    name: "niederlassungen___de-de",
    path: "/niederlassungen/",
    meta: indexrXysU5diG6Meta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/index.vue").then(m => m.default || m)
  },
  {
    name: "niederlassungen___cs-cz",
    path: "/pobocky/",
    meta: indexrXysU5diG6Meta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/index.vue").then(m => m.default || m)
  },
  {
    name: "niederlassungen___pl-pl",
    path: "/salony/",
    meta: indexrXysU5diG6Meta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/index.vue").then(m => m.default || m)
  },
  {
    name: "niederlassungen___de-ch",
    path: "/niederlassungen/",
    meta: indexrXysU5diG6Meta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/index.vue").then(m => m.default || m)
  },
  {
    name: "niederlassungen___fr-ch",
    path: "/succursales/",
    meta: indexrXysU5diG6Meta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/index.vue").then(m => m.default || m)
  },
  {
    name: "niederlassungen___it-ch",
    path: "/filiali/",
    meta: indexrXysU5diG6Meta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/index.vue").then(m => m.default || m)
  },
  {
    name: "niederlassungen-stadt-county-city___de-at",
    path: "/niederlassungen/stadt/:county()/:city()/",
    meta: _91city_93h5pLR7DvYsMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/stadt/[county]/[city].vue").then(m => m.default || m)
  },
  {
    name: "niederlassungen-stadt-county-city___de-de",
    path: "/niederlassungen/stadt/:county()/:city()/",
    meta: _91city_93h5pLR7DvYsMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/stadt/[county]/[city].vue").then(m => m.default || m)
  },
  {
    name: "niederlassungen-stadt-county-city___cs-cz",
    path: "/pobocky/mesto/:county()/:city()/",
    meta: _91city_93h5pLR7DvYsMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/stadt/[county]/[city].vue").then(m => m.default || m)
  },
  {
    name: "niederlassungen-stadt-county-city___pl-pl",
    path: "/salony/miasto/:county()/:city()/",
    meta: _91city_93h5pLR7DvYsMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/stadt/[county]/[city].vue").then(m => m.default || m)
  },
  {
    name: "niederlassungen-stadt-county-city___de-ch",
    path: "/niederlassungen/stadt/:county()/:city()/",
    meta: _91city_93h5pLR7DvYsMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/stadt/[county]/[city].vue").then(m => m.default || m)
  },
  {
    name: "niederlassungen-stadt-county-city___fr-ch",
    path: "/succursales/ville/:county()/:city()/",
    meta: _91city_93h5pLR7DvYsMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/stadt/[county]/[city].vue").then(m => m.default || m)
  },
  {
    name: "niederlassungen-stadt-county-city___it-ch",
    path: "/filiali/citta/:county()/:city()/",
    meta: _91city_93h5pLR7DvYsMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/stadt/[county]/[city].vue").then(m => m.default || m)
  },
  {
    name: "overlays-group-slug___de-at",
    path: "/overlays/:group()/:slug()/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/overlays/[group]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "overlays-group-slug___de-de",
    path: "/overlays/:group()/:slug()/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/overlays/[group]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "overlays-group-slug___cs-cz",
    path: "/overlays/:group()/:slug()/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/overlays/[group]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "overlays-group-slug___pl-pl",
    path: "/overlays/:group()/:slug()/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/overlays/[group]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "overlays-group-slug___de-ch",
    path: "/overlays/:group()/:slug()/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/overlays/[group]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "overlays-group-slug___fr-ch",
    path: "/overlays/:group()/:slug()/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/overlays/[group]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "overlays-group-slug___it-ch",
    path: "/overlays/:group()/:slug()/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/overlays/[group]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "p-slug___de-at",
    path: "/p/:slug()/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/[slug].vue").then(m => m.default || m)
  },
  {
    name: "p-slug___de-de",
    path: "/p/:slug()/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/[slug].vue").then(m => m.default || m)
  },
  {
    name: "p-slug___cs-cz",
    path: "/p/:slug()/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/[slug].vue").then(m => m.default || m)
  },
  {
    name: "p-slug___pl-pl",
    path: "/p/:slug()/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/[slug].vue").then(m => m.default || m)
  },
  {
    name: "p-slug___de-ch",
    path: "/p/:slug()/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/[slug].vue").then(m => m.default || m)
  },
  {
    name: "p-slug___fr-ch",
    path: "/p/:slug()/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/[slug].vue").then(m => m.default || m)
  },
  {
    name: "p-slug___it-ch",
    path: "/p/:slug()/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/[slug].vue").then(m => m.default || m)
  },
  {
    name: "p-cleaning-fluid-pdp___de-at",
    path: "/p/cleaning-fluid-pdp/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/cleaning-fluid-pdp.vue").then(m => m.default || m)
  },
  {
    name: "p-cleaning-fluid-pdp___de-de",
    path: "/p/cleaning-fluid-pdp/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/cleaning-fluid-pdp.vue").then(m => m.default || m)
  },
  {
    name: "p-cleaning-fluid-pdp___cs-cz",
    path: "/p/cleaning-fluid-pdp/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/cleaning-fluid-pdp.vue").then(m => m.default || m)
  },
  {
    name: "p-cleaning-fluid-pdp___pl-pl",
    path: "/p/cleaning-fluid-pdp/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/cleaning-fluid-pdp.vue").then(m => m.default || m)
  },
  {
    name: "p-cleaning-fluid-pdp___de-ch",
    path: "/p/cleaning-fluid-pdp/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/cleaning-fluid-pdp.vue").then(m => m.default || m)
  },
  {
    name: "p-cleaning-fluid-pdp___fr-ch",
    path: "/p/cleaning-fluid-pdp/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/cleaning-fluid-pdp.vue").then(m => m.default || m)
  },
  {
    name: "p-cleaning-fluid-pdp___it-ch",
    path: "/p/cleaning-fluid-pdp/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/cleaning-fluid-pdp.vue").then(m => m.default || m)
  },
  {
    name: "p-contact-lens-pdp___de-at",
    path: "/p/contact-lens-pdp/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/contact-lens-pdp.vue").then(m => m.default || m)
  },
  {
    name: "p-contact-lens-pdp___de-de",
    path: "/p/contact-lens-pdp/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/contact-lens-pdp.vue").then(m => m.default || m)
  },
  {
    name: "p-contact-lens-pdp___cs-cz",
    path: "/p/contact-lens-pdp/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/contact-lens-pdp.vue").then(m => m.default || m)
  },
  {
    name: "p-contact-lens-pdp___pl-pl",
    path: "/p/contact-lens-pdp/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/contact-lens-pdp.vue").then(m => m.default || m)
  },
  {
    name: "p-contact-lens-pdp___de-ch",
    path: "/p/contact-lens-pdp/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/contact-lens-pdp.vue").then(m => m.default || m)
  },
  {
    name: "p-contact-lens-pdp___fr-ch",
    path: "/p/contact-lens-pdp/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/contact-lens-pdp.vue").then(m => m.default || m)
  },
  {
    name: "p-contact-lens-pdp___it-ch",
    path: "/p/contact-lens-pdp/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/contact-lens-pdp.vue").then(m => m.default || m)
  },
  {
    name: "p-glasses-pdp___de-at",
    path: "/p/glasses-pdp/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/glasses-pdp.vue").then(m => m.default || m)
  },
  {
    name: "p-glasses-pdp___de-de",
    path: "/p/glasses-pdp/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/glasses-pdp.vue").then(m => m.default || m)
  },
  {
    name: "p-glasses-pdp___cs-cz",
    path: "/p/glasses-pdp/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/glasses-pdp.vue").then(m => m.default || m)
  },
  {
    name: "p-glasses-pdp___pl-pl",
    path: "/p/glasses-pdp/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/glasses-pdp.vue").then(m => m.default || m)
  },
  {
    name: "p-glasses-pdp___de-ch",
    path: "/p/glasses-pdp/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/glasses-pdp.vue").then(m => m.default || m)
  },
  {
    name: "p-glasses-pdp___fr-ch",
    path: "/p/glasses-pdp/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/glasses-pdp.vue").then(m => m.default || m)
  },
  {
    name: "p-glasses-pdp___it-ch",
    path: "/p/glasses-pdp/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/glasses-pdp.vue").then(m => m.default || m)
  },
  {
    name: "p-merch-pdp___de-at",
    path: "/p/merch-pdp/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/merch-pdp.vue").then(m => m.default || m)
  },
  {
    name: "p-merch-pdp___de-de",
    path: "/p/merch-pdp/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/merch-pdp.vue").then(m => m.default || m)
  },
  {
    name: "p-merch-pdp___cs-cz",
    path: "/p/merch-pdp/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/merch-pdp.vue").then(m => m.default || m)
  },
  {
    name: "p-merch-pdp___pl-pl",
    path: "/p/merch-pdp/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/merch-pdp.vue").then(m => m.default || m)
  },
  {
    name: "p-merch-pdp___de-ch",
    path: "/p/merch-pdp/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/merch-pdp.vue").then(m => m.default || m)
  },
  {
    name: "p-merch-pdp___fr-ch",
    path: "/p/merch-pdp/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/merch-pdp.vue").then(m => m.default || m)
  },
  {
    name: "p-merch-pdp___it-ch",
    path: "/p/merch-pdp/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/merch-pdp.vue").then(m => m.default || m)
  },
  {
    name: "p-rx-glasses-pdp___de-at",
    path: "/p/rx-glasses-pdp/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/rx-glasses-pdp.vue").then(m => m.default || m)
  },
  {
    name: "p-rx-glasses-pdp___de-de",
    path: "/p/rx-glasses-pdp/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/rx-glasses-pdp.vue").then(m => m.default || m)
  },
  {
    name: "p-rx-glasses-pdp___cs-cz",
    path: "/p/rx-glasses-pdp/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/rx-glasses-pdp.vue").then(m => m.default || m)
  },
  {
    name: "p-rx-glasses-pdp___pl-pl",
    path: "/p/rx-glasses-pdp/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/rx-glasses-pdp.vue").then(m => m.default || m)
  },
  {
    name: "p-rx-glasses-pdp___de-ch",
    path: "/p/rx-glasses-pdp/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/rx-glasses-pdp.vue").then(m => m.default || m)
  },
  {
    name: "p-rx-glasses-pdp___fr-ch",
    path: "/p/rx-glasses-pdp/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/rx-glasses-pdp.vue").then(m => m.default || m)
  },
  {
    name: "p-rx-glasses-pdp___it-ch",
    path: "/p/rx-glasses-pdp/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/rx-glasses-pdp.vue").then(m => m.default || m)
  },
  {
    name: "ropo-basket___de-at",
    path: "/anprobeliste/",
    meta: ropo_45basketmTSzJt8DH9Meta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/ropo-basket.vue").then(m => m.default || m)
  },
  {
    name: "ropo-basket___de-de",
    path: "/anprobeliste/",
    meta: ropo_45basketmTSzJt8DH9Meta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/ropo-basket.vue").then(m => m.default || m)
  },
  {
    name: "ropo-plp___de-at",
    path: "/anprobevorort/",
    meta: ropo_45plpVOtPSZhmJtMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/ropo-plp.vue").then(m => m.default || m)
  },
  {
    name: "ropo-plp___de-de",
    path: "/anprobevorort/",
    meta: ropo_45plpVOtPSZhmJtMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/ropo-plp.vue").then(m => m.default || m)
  },
  {
    name: "search___de-at",
    path: "/search/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de-de",
    path: "/search/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___cs-cz",
    path: "/search/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___pl-pl",
    path: "/search/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de-ch",
    path: "/search/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___fr-ch",
    path: "/search/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___it-ch",
    path: "/search/",
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "service-auftragsstatus___de-at",
    path: "/service/auftragsstatus/",
    meta: auftragsstatusVXzmIvJBbHMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/auftragsstatus.vue").then(m => m.default || m)
  },
  {
    name: "service-auftragsstatus___de-de",
    path: "/service/auftragsstatus/",
    meta: auftragsstatusVXzmIvJBbHMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/auftragsstatus.vue").then(m => m.default || m)
  },
  {
    name: "service-auftragsstatus___pl-pl",
    path: "/uslugi/status/",
    meta: auftragsstatusVXzmIvJBbHMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/auftragsstatus.vue").then(m => m.default || m)
  },
  {
    name: "service-auftragsstatus___de-ch",
    path: "/service/auftragsstatus/",
    meta: auftragsstatusVXzmIvJBbHMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/auftragsstatus.vue").then(m => m.default || m)
  },
  {
    name: "service-auftragsstatus___fr-ch",
    path: "/service/statut/",
    meta: auftragsstatusVXzmIvJBbHMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/auftragsstatus.vue").then(m => m.default || m)
  },
  {
    name: "service-auftragsstatus___it-ch",
    path: "/servizi/stato/",
    meta: auftragsstatusVXzmIvJBbHMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/auftragsstatus.vue").then(m => m.default || m)
  },
  {
    name: "service-gebrauchsanweisungen___de-at",
    path: "/service/gebrauchsanweisungen/",
    meta: gebrauchsanweisungenakdmtwld5eMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/gebrauchsanweisungen.vue").then(m => m.default || m)
  },
  {
    name: "service-gebrauchsanweisungen___de-de",
    path: "/service/gebrauchsanweisungen/",
    meta: gebrauchsanweisungenakdmtwld5eMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/gebrauchsanweisungen.vue").then(m => m.default || m)
  },
  {
    name: "service-gebrauchsanweisungen___cs-cz",
    path: "/servis/navod-k-pouziti/",
    meta: gebrauchsanweisungenakdmtwld5eMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/gebrauchsanweisungen.vue").then(m => m.default || m)
  },
  {
    name: "service-gebrauchsanweisungen___pl-pl",
    path: "/uslugi/instrukcje-uzytkowania/",
    meta: gebrauchsanweisungenakdmtwld5eMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/gebrauchsanweisungen.vue").then(m => m.default || m)
  },
  {
    name: "service-gebrauchsanweisungen___de-ch",
    path: "/service/gebrauchsanweisungen/",
    meta: gebrauchsanweisungenakdmtwld5eMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/gebrauchsanweisungen.vue").then(m => m.default || m)
  },
  {
    name: "service-gebrauchsanweisungen___fr-ch",
    path: "/service/mode-demploi/",
    meta: gebrauchsanweisungenakdmtwld5eMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/gebrauchsanweisungen.vue").then(m => m.default || m)
  },
  {
    name: "service-gebrauchsanweisungen___it-ch",
    path: "/servizi/istruzioni-per-l-uso/",
    meta: gebrauchsanweisungenakdmtwld5eMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/gebrauchsanweisungen.vue").then(m => m.default || m)
  },
  {
    name: "service-konformitaetserklaerungen___de-at",
    path: "/service/konformitaetserklaerungen/",
    meta: konformitaetserklaerungenoPVdGOzYRfMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/konformitaetserklaerungen.vue").then(m => m.default || m)
  },
  {
    name: "service-konformitaetserklaerungen___de-de",
    path: "/service/konformitaetserklaerungen/",
    meta: konformitaetserklaerungenoPVdGOzYRfMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/konformitaetserklaerungen.vue").then(m => m.default || m)
  },
  {
    name: "service-konformitaetserklaerungen___cs-cz",
    path: "/servis/eu-prohlaseni-o-shode/",
    meta: konformitaetserklaerungenoPVdGOzYRfMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/konformitaetserklaerungen.vue").then(m => m.default || m)
  },
  {
    name: "service-konformitaetserklaerungen___pl-pl",
    path: "/uslugi/deklaracja-zgodnosci/",
    meta: konformitaetserklaerungenoPVdGOzYRfMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/konformitaetserklaerungen.vue").then(m => m.default || m)
  },
  {
    name: "service-konformitaetserklaerungen___de-ch",
    path: "/service/konformitaetserklaerungen/",
    meta: konformitaetserklaerungenoPVdGOzYRfMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/konformitaetserklaerungen.vue").then(m => m.default || m)
  },
  {
    name: "service-konformitaetserklaerungen___fr-ch",
    path: "/service/certificat-de-conformite/",
    meta: konformitaetserklaerungenoPVdGOzYRfMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/konformitaetserklaerungen.vue").then(m => m.default || m)
  },
  {
    name: "service-konformitaetserklaerungen___it-ch",
    path: "/servizi/dichiarazione-di-conformita/",
    meta: konformitaetserklaerungenoPVdGOzYRfMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/konformitaetserklaerungen.vue").then(m => m.default || m)
  },
  {
    name: "signin___de-at",
    path: "/signin/",
    meta: signinHDEBWpeZkmMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: "signin___de-de",
    path: "/signin/",
    meta: signinHDEBWpeZkmMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: "signin___cs-cz",
    path: "/signin/",
    meta: signinHDEBWpeZkmMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: "signin___pl-pl",
    path: "/signin/",
    meta: signinHDEBWpeZkmMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: "signin___de-ch",
    path: "/signin/",
    meta: signinHDEBWpeZkmMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: "signin___fr-ch",
    path: "/signin/",
    meta: signinHDEBWpeZkmMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: "signin___it-ch",
    path: "/signin/",
    meta: signinHDEBWpeZkmMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: "success___de-at",
    path: "/success/",
    meta: successZCNJSmmWCtMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/success.vue").then(m => m.default || m)
  },
  {
    name: "success___de-de",
    path: "/success/",
    meta: successZCNJSmmWCtMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/success.vue").then(m => m.default || m)
  },
  {
    name: "success___cs-cz",
    path: "/success/",
    meta: successZCNJSmmWCtMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/success.vue").then(m => m.default || m)
  },
  {
    name: "success___pl-pl",
    path: "/success/",
    meta: successZCNJSmmWCtMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/success.vue").then(m => m.default || m)
  },
  {
    name: "success___de-ch",
    path: "/success/",
    meta: successZCNJSmmWCtMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/success.vue").then(m => m.default || m)
  },
  {
    name: "success___fr-ch",
    path: "/success/",
    meta: successZCNJSmmWCtMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/success.vue").then(m => m.default || m)
  },
  {
    name: "success___it-ch",
    path: "/success/",
    meta: successZCNJSmmWCtMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/success.vue").then(m => m.default || m)
  },
  {
    name: "wishlist___de-at",
    path: "/wishlist/",
    meta: wishlists2nZdBkxyIMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "wishlist___de-de",
    path: "/wishlist/",
    meta: wishlists2nZdBkxyIMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "wishlist___cs-cz",
    path: "/wishlist/",
    meta: wishlists2nZdBkxyIMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "wishlist___pl-pl",
    path: "/wishlist/",
    meta: wishlists2nZdBkxyIMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "wishlist___de-ch",
    path: "/wishlist/",
    meta: wishlists2nZdBkxyIMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "wishlist___fr-ch",
    path: "/wishlist/",
    meta: wishlists2nZdBkxyIMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "wishlist___it-ch",
    path: "/wishlist/",
    meta: wishlists2nZdBkxyIMeta || {},
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/wishlist.vue").then(m => m.default || m)
  }
]