export default defineNuxtPlugin((_context) => {
  const { $currentShop } = useNuxtApp()
  const src = $currentShop?.syteScriptTagUrl
  if (!src || !getIsSyteEnabled($currentShop)) {
    return
  }
  useHead(() => ({
    script: [
      {
        src,
        type: 'text/javascript',
        key: 'syte-script',
        async: true,
      },
    ],
  }))
})
